"use strict";

require.config({
	waitSeconds: 0, // disable loading timeout for slow connections
	paths: {

		// Libraries
		'requireLib'                : '../../../vendor/requirejs/require',
		// 'zepto'                     : '../../../vendor/zepto/dist/zepto',
		'jquery'                    : '../../../vendor/jquery/dist/jquery',
		// 'jquery-jsonp'              : '../../../vendor/jquery-jsonp/src/jquery.jsonp',
		'jquery-mousewheel'         : '../../../vendor/jquery-mousewheel/jquery.mousewheel',
		'jquery-xdomainrequest'		: '../../../vendor/jquery-xdomainrequest/jQuery.XDomainRequest',
		'i18next'                   : '../../../vendor/i18next/release/i18next-1.7.3',
		'modernizr'                 : '../../../vendor/build/modernizr/modernizr-viewer',
		// 'isMobile'                  : '../../../vendor/isMobile/isMobile',
		'bowser'					: '../../../vendor/bowser/src/bowser',
		'velocity'                  : '../../../vendor/velocity/velocity',
		'swfobject'                 : '../../../vendor/swfobject/src/swfobject',
		'underscore'                : '../../../vendor/underscore/underscore',
		'hammerjs'                  : '../../../vendor/hammer/hammer',
		'jquery-hammer'             : '../../../vendor/jquery-hammer/jquery.hammer',
		'iscroll'                   : '../../../vendor/iscroll/build/iscroll-custom',
		'urljs'                     : '../../../vendor/urljs/url',
		'screenfull'                : '../../../vendor/screenfulljs/src/screenfull',
		'lockr'						: '../../../vendor/lockr/lockr',
		'webfontloader'				: '../../../vendor/webfontloader/webfontloader',
		'statsjs'					: '../../../vendor/stats.js/src/Stats',
		'ifvisible'					: '../../../vendor/ifvisible-js/src/ifvisible',
		'protobufjs/minimal'		: '../../../vendor/protobuf.js/dist/minimal/protobuf.min',
		'long'						: '../../../vendor/long.js/dist/long.min',

		'ravenjs'					: '../../../vendor/raven-js/dist/raven',
		'ravenjs-requirejs'			: '../../../vendor/raven-js/dist/plugins/require',

		// *****

		// Services
		'service'                   : 'helpers/service',

		// Config services
		'config-base'               : 'helpers/config/config',
		'service-config'            : 'helpers/config/service-config',
		'service-config-loader'     : 'helpers/config/service-config-loader',
		'service-config-book'       : 'helpers/config/service-config-book',
		'service-config-viewer'     : 'helpers/config/service-config-viewer',

		// Data services
		'service-data'              : 'helpers/data/service-data',
		'service-data-debug'        : 'helpers/data/service-data-debug',
		'service-data-book'         : 'helpers/data/service-data-book',
		'service-data-links'        : 'helpers/data/service-data-links',
		'service-data-toc'          : 'helpers/data/service-data-toc',
		'service-data-related'      : 'helpers/data/service-data-related',
		'service-data-search'       : 'helpers/data/service-data-search',
		'service-data-locales'      : 'helpers/data/service-data-locales',
		'service-data-skin'         : 'helpers/data/service-data-skin',

		// *****

		// Managers
		'manager'                   : 'helpers/manager',
		'manager-viewport'          : 'helpers/viewport/manager-viewport',
		'manager-skin'              : 'helpers/skin/manager-skin',
		'manager-skin-extended'     : 'helpers/skin/manager-skin-extended',
		'manager-skin-button'       : 'helpers/skin/manager-skin-button',
		'manager-bgsound'           : 'helpers/sound/manager-bgsound',
		'manager-audiofx'           : 'helpers/sound/manager-audiofx',
		'manager-effect'            : 'helpers/effects/manager-effect',
		'manager-keyboard'          : 'helpers/keyboard/manager-keyboard',
		'manager-event'             : 'helpers/events/manager-event',
		'manager-cursor'            : 'helpers/cursors/manager-cursor',
		'manager-tooltip'           : 'helpers/tooltips/manager-tooltip',
		'manager-notification'      : 'helpers/notifications/manager-notification',
		'manager-tracking'          : 'helpers/tracking/manager-tracking',
		'manager-popup'             : 'helpers/popups/manager-popup',
		'manager-mini'              : 'helpers/mini/manager-mini',
		'manager-link'              : 'helpers/links/manager-link',
		'manager-api'               : 'helpers/api/manager-api',
		'manager-zoom'              : 'helpers/zoom/manager-zoom',
		'manager-print'             : 'helpers/print/manager-print',
		'manager-contextmenu'       : 'helpers/contextmenu/manager-contextmenu',
		'manager-clipboard'         : 'helpers/clipboard/manager-clipboard',
		'manager-stack'             : 'helpers/views/manager-stack',
		'manager-history'			: 'helpers/history/manager-history',
		'manager-config'			: 'helpers/config/manager-config',
		'manager-debug'				: 'helpers/debug/manager-debug',
		'manager-search'			: 'helpers/search/manager-search',
		'manager-integration'		: 'helpers/integrations/manager-integration',
		'manager-trigger'			: 'helpers/integrations/manager-trigger',

		// *****

		// Protobuf decoders
		'proto-decoder-book-link'   : '../../../vendor/proto/book-link',
		'proto-decoder-book-text'   : '../../../vendor/proto/book-text',
		'proto-decoder-book-toc'    : '../../../vendor/proto/book-toc',

		// *****

		// API
		'api-version1'               	: 'helpers/api/versions/api-version1',
		'api-version2'               	: 'helpers/api/versions/api-version2',

		// *****

		// Notification
		'notification'              : 'helpers/notifications/notification',
		'notification-info'         : 'helpers/notifications/notification-info',
		'notification-warning'      : 'helpers/notifications/notification-warning',
		'notification-danger'       : 'helpers/notifications/notification-danger',
		'notification-success'      : 'helpers/notifications/notification-success',

		// *****

		// Analytics
		'tracker'                   : 'helpers/tracking/tracker',
		'tracker-ga'                : 'helpers/tracking/tracker-ga',
		'tracker-ga-customer'       : 'helpers/tracking/tracker-ga-customer',
		'tracker-internal'          : 'helpers/tracking/tracker-internal',
		'tracker-analytics'         : 'helpers/tracking/tracker-analytics',
		'tracker-ga4'               : 'helpers/tracking/tracker-ga4',
		'tracker-ga4-calameo'       : 'helpers/tracking/tracker-ga4-calameo',
		'tracker-ga4-customer'      : 'helpers/tracking/tracker-ga4-customer',

		// *****

		// Models
		'model-coord'               : 'helpers/models/coord',
		'model-rect'                : 'helpers/models/rect',

		// *****

		// Lightbox
		'lightbox'               	: 'helpers/lightbox/lightbox',

		// *****

		// Consents
		'cmp'		           		: 'helpers/consents/cookiebot/view',

		// *****

		// Debugger
		'debugger'               	: 'debugger/debugger',
		'debugger-template'         : 'debugger/template',

		// *****

		// Utils
		'util'                      : 'helpers/util',
		'util-url'                  : 'helpers/utils/util-url',
		'util-cookie'               : 'helpers/utils/util-cookie',
		'util-color'                : 'helpers/utils/util-color',
		'util-flash'                : '../../../vendor/utils/flash',

		// *****

		// Behaviors
		'behavior-manager'          : 'helpers/behaviors/manager-behavior',
		'behavior-common'           : 'helpers/behaviors/custom/behavior-common',
		'behavior-glow'             : 'helpers/behaviors/custom/behavior-glow',
		'behavior-hover'            : 'helpers/behaviors/custom/behavior-hover',
		'behavior-navigation'       : 'helpers/behaviors/custom/behavior-navigation',
		'behavior-screen'           : 'helpers/behaviors/custom/behavior-screen',
		'behavior-shadow'           : 'helpers/behaviors/custom/behavior-shadow',
		'behavior-toggle'           : 'helpers/behaviors/custom/behavior-toggle',
		'behavior-showonpages'      : 'helpers/behaviors/custom/behavior-showonpages',

		// *****

		// Tooltips
		'tooltip'                   : 'helpers/tooltips/tooltip',
		'tooltip-action'            : 'helpers/tooltips/tooltip-action',
		'tooltip-link'              : 'helpers/tooltips/tooltip-link',
		'tooltip-video'             : 'helpers/tooltips/tooltip-video',
		'tooltip-page'              : 'helpers/tooltips/tooltip-page',
		'tooltip-page-scroll'       : 'helpers/tooltips/tooltip-page-scroll',

		// *****

		// Popups
		'popup'                     : 'helpers/popups/popup',
		'popup-toc'                 : 'helpers/popups/popup-toc',
		'popup-related'             : 'helpers/popups/popup-related',
		'popup-search'              : 'helpers/popups/popup-search',
		'popup-audio'               : 'helpers/popups/popup-audio',

		// Items
		'popup-item'                : 'helpers/popups/items/item',
		'popup-item-related'        : 'helpers/popups/items/item-related',
		'popup-item-search'         : 'helpers/popups/items/item-search',
		'popup-item-toc'            : 'helpers/popups/items/item-toc',

		// *****

		// Links
		'link'                      : 'helpers/links/link',
		'link-internal'             : 'helpers/links/link-internal',
		'link-external'             : 'helpers/links/link-external',
		'link-media'                : 'helpers/links/link-media',
		'link-audio'                : 'helpers/links/link-audio',
		'link-video'                : 'helpers/links/link-video',
		'link-focus'                : 'helpers/links/link-focus',
		'link-shopping'             : 'helpers/links/link-shopping',
		'link-integration'          : 'helpers/links/link-integration',

		// *****

		// Skin
		'skin-layout'               : 'helpers/skin/skin-layout',
		'skin-element'              : 'helpers/skin/skin-element',
		'skin-tag'                  : 'helpers/skin/skin-tag',
		'skin-button'               : 'helpers/skin/skin-button',
		'skin-spacer'               : 'helpers/skin/skin-spacer',
		'skin-page-number'          : 'helpers/skin/skin-page-number',
		'skin-slider'               : 'helpers/skin/skin-slider',
		'skin-slider-volume'        : 'helpers/skin/skin-slider-volume',
		'skin-slider-zoom'          : 'helpers/skin/skin-slider-zoom',
		'skin-slider-page'          : 'helpers/skin/skin-slider-page',
		'skin-slider-page-number'   : 'helpers/skin/skin-slider-page-number',
		'skin-branding'             : 'helpers/skin/skin-branding',
		'skin-dock'                 : 'helpers/skin/skin-dock',

		'skin-gfx-div'              : 'helpers/skin/assets/gfx/skin-gfx-div',
		'skin-gfx-img'              : 'helpers/skin/assets/gfx/skin-gfx-img',
		'skin-gfx-canvas'           : 'helpers/skin/assets/gfx/skin-gfx-canvas',
		'skin-gfx-svg'              : 'helpers/skin/assets/gfx/skin-gfx-svg',

		'skin-asset'                : 'helpers/skin/assets/skin-asset',
		'skin-asset-internal'       : 'helpers/skin/assets/skin-asset-internal',
		'skin-asset-text'           : 'helpers/skin/assets/skin-asset-text',
		'skin-asset-svg'            : 'helpers/skin/assets/skin-asset-svg',
		'skin-asset-maps'           : 'helpers/skin/assets/skin-asset-maps',
		'manager-skin-assets'       : 'helpers/skin/assets/manager-skin-assets',

		// *****

		// Styles
		'style'                     : 'helpers/styles/style',
		'style-default'             : 'helpers/styles/style-default',
		'style-viewer'              : 'helpers/styles/style-viewer',

		'style-audioplayer'         : 'helpers/styles/items/style-audioplayer',
		'style-common'              : 'helpers/styles/items/style-common',
		'style-cursor'              : 'helpers/styles/items/style-cursor',
		'style-dock'                : 'helpers/styles/items/style-dock',
		'style-field'               : 'helpers/styles/items/style-field',
		'style-lightbox'            : 'helpers/styles/items/style-lightbox',
		'style-link'                : 'helpers/styles/items/style-link',
		'style-pagenumber'          : 'helpers/styles/items/style-pagenumber',
		'style-popup'               : 'helpers/styles/items/style-popup',
		'style-print'               : 'helpers/styles/items/style-print',
		'style-related'             : 'helpers/styles/items/style-related',
		'style-scroller'            : 'helpers/styles/items/style-scroller',
		'style-search'              : 'helpers/styles/items/style-search',
		'style-share'               : 'helpers/styles/items/style-share',
		'style-slider'              : 'helpers/styles/items/style-slider',
		'style-sound'               : 'helpers/styles/items/style-sound',
		'style-thumb'               : 'helpers/styles/items/style-thumb',
		'style-toc'                 : 'helpers/styles/items/style-toc',
		'style-tooltip'             : 'helpers/styles/items/style-tooltip',
		'style-videoplayer'         : 'helpers/styles/items/style-videoplayer',
		'style-view'                : 'helpers/styles/items/style-view',

		// *****

		// Layouts
		'layout'                    : 'helpers/layouts/layout',
		'layout-viewport'           : 'helpers/layouts/layout-viewport',
		'layout-main'               : 'helpers/layouts/layout-main',
		'layout-ad'               	: 'helpers/layouts/layout-ad',
		'layout-viewer'             : 'helpers/layouts/layout-viewer',
		'layout-viewer-inner'       : 'helpers/layouts/layout-viewer-inner',

		// *****

		// Subscribers
		'subscriber-login'          : 'helpers/subscribers/subscriber-login',
		// *****

		// UI
		'events'                    : 'helpers/events/events',
		'ui-view'                   : 'helpers/ui/ui-view',
		'ui-element'                : 'helpers/ui/ui-element',
		'ui-button'                 : 'helpers/ui/ui-button',
		'ui-slider'                 : 'helpers/ui/ui-slider',
		'ui-toggle-button'          : 'helpers/ui/ui-toggle-button',
		'ui-thumbnail'              : 'helpers/ui/ui-thumbnail',

		// *****

		// Ads
		'ad'                        : 'helpers/ad/ad',
		'ad-dfp'                    : 'helpers/ad/ad-dfp',
		'ad-oko'                    : 'helpers/ad/ad-oko',

		// *****

		// Search
		'search-accent-map'			: 'helpers/search/accent-map',

		// *****

		// Views
		'view'                      : 'helpers/views/view',
		'view-book'                 : 'helpers/views/view-book',
		'view-slide'                : 'helpers/views/view-slide',
		'view-scroll'               : 'helpers/views/view-scroll',
		'view-index'           	    : 'helpers/views/view-index',
		'view-thumbnail'            : 'helpers/views/view-thumbnail',
		'view-page-holder'          : 'helpers/views/view-page-holder',
		'view-swipe'                : 'helpers/views/view-swipe',
		'view-cover'                : 'helpers/views/view-cover',

		// *****

		// Viewers
		'viewer-flash'              : 'modules/flash/viewer-flash',
		'viewer-desktop'            : 'modules/desktop/viewer-desktop',
		'viewer-mobile'        		: 'modules/mobile/viewer-mobile',
		'viewer-twittercard'		: 'modules/twittercard/viewer-twittercard',

		// Flash handlers
		'flash-handler-externalinterface'	: 'modules/flash/handlers/handler-externalinterface',
		'flash-handler-mousewheel'			: 'modules/flash/handlers/handler-mousewheel',
		'flash-handler-fullscreen'			: 'modules/flash/handlers/handler-fullscreen',
		'flash-handler-mediabox'			: 'modules/flash/handlers/handler-mediabox',
		'flash-handler-lightbox'			: 'modules/flash/handlers/handler-lightbox',
		'flash-handler-ad'					: 'modules/flash/handlers/handler-ad',

		// Misc
		'constants'                 : 'helpers/constants',
		'status'                    : 'helpers/status',
		'sound-controller'          : 'helpers/sound/sound-controller',
		'viewer'                    : 'modules/viewer',
		'sound-player'              : 'helpers/sound/sound-player',
		'login'                     : 'helpers/login/login',
		'pageflip'                  : 'helpers/pageflip',

		'calameo-integration-sdk'   : '../../../vendor/build/integrations/sdk',
	},
	shim: {
		'i18next'					: {exports: 'i18n'},
		'modernizr'					: {exports: 'Modernizr'},
		'swfobject'					: {exports: 'swfobject'},
		'underscore'				: {exports: '_'},
		// 'zepto'						: {exports: '$'},
		'urljs'						: {exports: 'URL'},
		// 'jquery-jsonp'				: {deps:['jquery']},
		'jquery-hammer'				: {deps:['jquery','hammerjs']},
		'jquery-xdomainrequest'		: {deps:['jquery']},
		'wowbook'					: {deps:['jquery', 'jquery-browser', 'modernizr', 'jquery-hammer']},
		'velocity'					: {deps:['jquery']},
		'protobufjs/minimal'		: {deps:['long']},
		'decoder'					: {deps:['protobufjs/minimal']},
		'ravenjs-requirejs'			: {deps:['ravenjs']}
	}
	// ,
	// map: {
	// 	'*': {
	// 		'zepto': 'jquery'
	// 	}
	// }
});

define('loader',[
	'modernizr',
	'util',
	'jquery',
	'underscore',
	'constants',
	'service-config-loader',
	'service-config-book',
	'service-data-book',
	'service-data-locales',
	'service-data-debug',
	'manager-event',
	'manager-viewport',
	'manager-notification',
	'manager-debug',
	'subscriber-login',
	'helpers/loader/loading/view',
	'helpers/loader/error/view',
	'cmp',
	'bowser',
	'ravenjs',
	'ravenjs-requirejs',
	'jquery-xdomainrequest',
	'urljs'
], function (
	Modernizr,
	Util,
	$,
	_,
	$C,
	LoaderConfigSrvc,
	BookConfigSrvc,
	BookSrvc,
	LocalesSrvc,
	DebugSrvc,
	EventManager,
	ViewportManager,
	NotificationManager,
	DebugManager,
	SubscriberLogin,
	Loading,
	ErrorMessage,
	CMP,
	Bowser,
	Raven
){

	// Avoid sending errors when window is unloaded
	var unloadOccurred = false;

	window.onbeforeunload = function () {
	  unloadOccurred = true;
	};

	// Raven configuration
	Raven.config('https://c0673cb279fd4d39a06ffad484d611dd@sentry.io/222763', {
		debug: !!DEV,
		environment: !!DEV ? 'developement' : 'production',
		release: Calameo.config.buildid,
		autoBreadcrumbs: false,
		sampleRate: 1,
		whitelistUrls: [
			/^http[s]?:\/\/(?:[^\.]+\.)?calameo(?:assets)?\.(?:test|com)/
		],
		shouldSendCallback: function(data){
			if ( /(PhantomJS)/.test(window.navigator.userAgent) ) return false;
			return !!LoaderConfigSrvc.get('reportjs') && !unloadOccurred;
		},
		ignoreErrors: [
			'911',
			'923',
			'Error accessing locales',
			'Error accessing service',
			'Incorrect parameter',
			'Invalid book',
			'Invalid account',
			'Book still converting',
			'No viewer available for your device',
			'service-data-book => Loading error (error)',
			'service-data-book => Loading error (101) Invalid book',
			'service-data-book => Loading error (101) Unknown book',
			'service-data-book => Loading error (101) Book still converting',
			'service-data-book => Loading error (101) Invalid account',
			'service-data-book => Loading error (147) Incorrect origin',
			'service-data-book => Loading error (129) Too many requests',
			'service-data-links => Loading error (error)',
			'service-data-toc => Loading error (error)',
			'service-data-related => Loading error (error)',
			'service-data-locales => Loading error',
			'service-data-skin => Loading error (error)',
			'service-data-skin => Loading error (timeout) timeout',
			'service-data-skin => Loading error (error) Forbidden',
			'skin-asset => Loading error',
			'Unable to load skin assets',
			'Unable to load skin',
			'Unable to load sound player audio file',
			'Unable to load text.bin',
			'Unable to load viewer',
			'Unable to parse skin',
			'Too many requests',
			'Incorrect origin',
			'Unknown book',
			'Unknown error',
			'THREE is not defined',
			'Cannot set property "iframeReady" of undefined to "true"',
			'Failed to execute \'evaluate\' on \'Document\': The string \'\' is not a valid XPath expression.', // Selenium/PhantomJS error?
			'A security problem occurred',
			'Access is denied',
			'Accès refusé',
			'Acceso denegado',
			'Blocked a frame with origin',
			'Caractère incorrect',
			'Demande d\'accès à la méthode ou aux propriétés inattendue',
			'Les ressources mémoire disponibles sont insuffisantes pour exécuter cette opération.',
			'Error loading script',
			'Error setting property on NPObject!',
			'expected expression',
			'Identificateur attendu',
			'Invalid calling object',
			'Invalid character',
			'Invalid XML',
			'GIF89a',
			'Mismatched anonymous',
			'missing ; before statement',
			'Mémoire insuffisante',
			'Not enough storage is available to complete this operation',
			'Not implemented',
			'Non implementato',
			'Non implémenté',
			'No implementado',
			'Object expected',
			'Octal literals are not allowed in strict mode',
			'Opération abandonnée',
			'Operation aborted',
			'Out of memory',
			'Permiso denegado',
			'Permesso negato',
			'Permissão negada',
			'Permission denied',
			'Permission refusée',
			'ru is not defined',
			'SecurityError',
			'Un problème de sécurité s\'est produit',
			'Unexpected identifier',
			'Unexpected string literal',
			'Unexpected token',
			'Unspecified error',
			'e.bind(null,s,0,4)', // Crawler related (protobuf implementation?)
			// Random plugins/extensions
			'top.GLOBALS',
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
			'originalCreateNotification',
			'canvas.contentDocument',
			'MyApp_RemoveAllHighlights',
			'http://tt.epicplay.com',
			'Can\'t find variable: ZiteReader',
			'jigsaw is not defined',
			'ComboSearch is not defined',
			'http://loading.retry.widdit.com/',
			'atomicFindClose',
			'KasperskyLab',
			'_avast_submit',
			// Facebook borked
			'fb_xd_fragment',
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
			// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
			'bmi_SafeAddOnload',
			'EBCallBackMessageReceived',
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			'conduitPage',
			// Generic error code from errors outside the security sandbox
			// You can delete this if using raven.js > 1.0, which ignores these automatically.
			'Script error'
		],
		ignoreUrls: [
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i
		]
	}).install();

	// Start the debug manager
	DebugManager.enable();
	DebugManager.startTime('ready');

	var CalameoLoader = function () {
		this.initialize.apply(this, arguments);
	};

	CalameoLoader.extend = Util.extend;

	new (CalameoLoader.extend({

		Loading: null,
		Login: null,
		Viewer: null,

		LoginRetries: 0,

		initialize: function (){

			console.log('==============================');
			console.log('Loading...');
			console.log('------------------------------');
			console.log('Parent :', Util.url.parent().toString());
			console.log('Self   :', Util.url.self().toString());

			// Prevent the loader script from running on third party domains
			if ( window.location.protocol != 'data:' && !Util.url.self().host().match(/^([^.]+\.)?calameo\.(localhost|test|com)$/i) ) return this.error(401, 'Incorrect domain');

			// Create the loading view
			this.showLoading();

			// Loading loader configuration
			LoaderConfigSrvc
				.load( {parameters: $.extend({}, Calameo.defaults, Calameo.config)} );

			// Load CMP
			CMP.load();

			// Set the background color if any
			if ( LoaderConfigSrvc.get('bgcolor') ) ViewportManager.css('backgroundColor', LoaderConfigSrvc.getBgColor('#'));

			ViewportManager.toggleClass('not-transparent', LoaderConfigSrvc.get('wmode') != 'transparent' );

			ViewportManager.toggleClass('is-safari-desktop', Util.isSafari() && !Util.isMobile() );

			EventManager.on('context:global', this.onGlobalHandler, this);
			EventManager.on('context:fullscreen', this.onFullscreenHandler, this);

			// *****

			// If the iframe is hidden (display: none), on Android, the viewport is 0x0
			if ( ViewportManager.width() == 1 && ViewportManager.height() == 1 )
			{
				console.log('Loader => Waiting for correct viewport size');

				// We need to wait for a resize to load and display the publication
				$(window).one('resize', $.proxy(function(){

					console.log('Loader => Viewport size is correct. Resuming...');

					// Update the viewport size now, we need the correct values to build the viewer
					ViewportManager.invalidate();

					// Start the building and loading
					this.start();

				}, this));

				return;
			}

			// Check if the viewer is in a sandboxed iframe
			if ( Util.isSandboxedIframe() === 'sandboxed' )
			{
				this.error(403, 'Content sandboxed', '', true);
				return;
			}

			CMP
			.promise()
			.then(
				$.proxy(function(consent) {
					this.start();
				}, this)
			);
		},

		start: function(){

			// Loading book configuration
			BookConfigSrvc
				.load( {parameters: $.extend({}, Calameo.vars)} )
				.fail(
					$.proxy(function(){

						return this.error(402, 'Incorrect parameter');

					}, this)
				)
				.done(
					$.proxy(function(){

						console.info('Loader => Loader config', LoaderConfigSrvc.get());
						console.info('Loader => Book config', BookConfigSrvc.get());

						// *****

						// Sand boxed mode for Flash viewer
						if ( LoaderConfigSrvc.get('sandboxed') ) return this.loadSandboxedViewer();

						// *****

						// Check for Flash viewer
						if ( ( !this.isForced() && !Util.isMobile() ) || LoaderConfigSrvc.get('forceViewer') == 'flash' )
						{
							if ( LoaderConfigSrvc.get('forceViewer') == 'flash' )
							{
								if ( this.isDesktopFlash() )
								{
									// Using Flash viewer
									return this.loadViewerModule( 'viewer-flash' );
								}
								else if ( !this.isDesktopHtml5() )
								{
									// Flash disabled or not installed
									return this.error(802, 'Flash not detected');
								}
							}
						}

						this.load();

					}, this)
				);

		},

		onGlobalHandler: function(e, data){
			switch ( data.type )
			{
				case 'loading':
					this.showLoading();
					break;

				case 'login':
					this.showLogin();
					break;

				case 'error':
					this.error( data.code, data.msg );
					break;

				case 'ready':
					this.hideLoading();

					DebugManager.endTime('ready');
					break;
			}
		},

		onFullscreenHandler: function(e, data){
			switch ( data.type )
			{
				case 'leave':
					this.hideLogin();
					break;
			}
		},

		showLoading: function(){

			if ( this.Loading )
			{
				this.Loading.show();
			}
			else
			{
				this.Loading = new Loading();
			}

		},

		hideLoading: function(){
			if ( this.Loading )
			{
				this.Loading.remove();
				this.Loading = null;
			}
		},

		showLogin: function()
		{
			console.warn('Loader => Login required');

			// Hide the loading
			this.hideLoading();

			NotificationManager.enable();

			ViewportManager.toggleClass('with-login', true);

			// // The keyboard is disabled in Safari fullscreen mode
			// // We need to exit the fullscreen mode
			// if ( !Util.isMobile() && Util.isSafari() && LoaderConfigSrvc.get('clickTo') == $C.CLICKTO_FULLSCREEN )
			// {
			// 	ViewportManager.exitFullscreen();
			// }

			// Display the login form
			this.Login = new SubscriberLogin();

			this.Login
				.promise()
				.fail(
					$.proxy(function(){

						this.hideLogin();

					}, this)
				)
				.done(
					$.proxy(function(login, password){

						console.warn('Loader => Credentials:', login, password);

						// Set the book config login and password parameters
						BookConfigSrvc.set('login', login);
						BookConfigSrvc.set('password', password);

						this.LoginRetries++;

						// // If the mini should open in fullscreen, trigger the fullscreen if not already enabled
						// if ( !Util.isMobile() && Util.isSafari() && LoaderConfigSrvc.get('clickTo') == $C.CLICKTO_FULLSCREEN )
						// {
						// 	ViewportManager.enterFullscreen();
						// }

						// Reload the data
						this.load();

					}, this)
				);
		},

		hideLogin: function()
		{
			ViewportManager.toggleClass('with-login', false);

			if ( this.Login )
			{
				this.Login.destroy();
				this.Login = null;
			}
		},

		checkAccess: function(){

			console.log('Loader => Checking access');

			// Check access
			if ( this.LoginRetries > 0 || LoaderConfigSrvc.get('mode') != $C.LOADER_MODE_MINI )
			{
				if ( !BookSrvc.feature('subscribers.access') )
				{
					// Can a subscriber try to access the publication ?
					if ( BookSrvc.feature('subscribers.enabled') )
					{
						if ( this.LoginRetries > 0 )
						{
							if ( this.__loginNotif ) this.__loginNotif.hide();
							this.__loginNotif = NotificationManager.error( LocalesSrvc.t('msg.incorrectLogin'), 2000 );
						}

						this.showLogin();

						return false;
					}
					else
					{
						// Access is denied
						this.error(403, 'Access denied', BookSrvc.getID());
						return false;
					}
				}
			}

			this.hideLogin();

			console.info('Loader => Access granted');

			return true;
		},

		error: function(code, msg, id, silent){

			var model = {
				code: code,
				msg: msg,
				id: ( id || '' ),
				help: {
					url: 'https://support.calameo.com',
					label: 'support.calameo.com'
				}
			};

			// Hide the loading
			this.hideLoading();

			$('body').addClass('not-transparent');

			if ( !silent )
			{
				DebugManager.error(msg, {code: code});

				if ( code >= 800 && !unloadOccurred )
				{
					// Send debug data automatically
					DebugSrvc
						.load({
							data: {
								type: 'error',
								error: code,
								payload: JSON.stringify( DebugManager.getDebugData() )
							}
						});
				}
			}

			var error = new ErrorMessage({model: model});
		},

		load: function (){

			// Display the loading spinner
			this.showLoading();
			this.hideLogin();

			DebugManager.startTime('data-book');

			// Load book data
			BookSrvc
				.load()
				.fail(
					$.proxy(this.onLoadError, this, 910, 'Error accessing service')
				)
				.done(
					$.proxy(this.loadLocales, this)
				);
		},

		loadLocales: function(fallback)
		{
			console.log('Loader => Loading locales');

			// Load locales
			LocalesSrvc
				.load(fallback)
				.fail(
					$.proxy(function(){
						fallback ? this.onLoadError(913, 'Error accessing locales') : this.loadLocales(true);
					}, this)
				)
				.done(
					$.proxy(this.onLoadSuccess, this)
				);
		},

		onLoadSuccess: function(){

			console.info('Loader => Book', BookSrvc.get());

			DebugManager.endTime('data-book');

			// DebugManager.check();

			// Check access
			if ( !this.checkAccess() ) return;

			if ( this.IsViewerLoaded )
			{
				EventManager.trigger('context:global', {type: 'load'});
				return;
			}

			this.loadCommonCSS();

			var viewerModule = '';

			switch( true ) {

				// Force by configuration
				case this.isForced():
					viewerModule = 'viewer-' + LoaderConfigSrvc.get('forceViewer');
					break;

				// load mobile version
				case this.isMobile():
					viewerModule = 'viewer-mobile';
					break;

			   // load full desktop version
				case this.isDesktopHtml5():
					viewerModule = 'viewer-desktop';
					break;

			   // load full desktop version
				case this.isDesktopFlash():
					viewerModule = 'viewer-flash';
					break;

				// load fallback
			  // 	case this.isDesktopFallback():
					// viewerModule = 'viewer-fallback';
			  //  		break;
			}

			// No viewer available for the device (uh oh?)
			if ( _.isEmpty(viewerModule) ) return this.error(801, 'No viewer available for your device');

			// Launch the viewer
			this.loadViewerModule( viewerModule );
		},

		onLoadError: function(code, msg, errCode, errMsg){
			errCode || ( errCode = code );
			errMsg || ( errMsg = msg );
			return this.error(errCode, errMsg, BookSrvc.getID());
		},

		loadCommonCSS: function(){
			if ( Calameo.defaults.svg && Calameo.defaults.svg.enabled )
			{
				BookSrvc.set('features.svg', Calameo.defaults.svg);
				BookSrvc.set('domains.svg', Calameo.defaults.svg.pages.url);
			}

			if ( !BookSrvc.feature('svg.enabled') ) return;

			var url = BookSrvc.feature('svg.css.url');

			if ( url ) $('head').append('<link rel="stylesheet" href="' + url + '" type="text/css" />');
		},

		loadViewerModule: function(viewerModule){
			console.warn('Loader => Loading viewer: ', viewerModule);

			// Tag the context
			Raven.setTagsContext({
				viewer: viewerModule
			});

			ViewportManager.addClass(viewerModule);

			// Save the viewer module
			window.viewerModule = viewerModule;

			require([viewerModule], $.proxy(function (viewer){

				// Success
				console.warn('Loader => Viewer loaded', viewerModule);
				this.IsViewerLoaded = true;

			}, this), $.proxy(function(err){

				// Error
				this.IsViewerLoaded = false;
				console.error('Loader => Unable to load viewer:', viewerModule, arguments);

				var errCode = 911;

				switch ( err.requireType )
				{
					case 'timeout':
						errCode = 921;
						break;

					case 'nodefine':
						errCode = 922;
						break;

					case 'scripterror':
						errCode = 923;
						break;

					default:
						Raven.captureException(err);
						break;
				}

				DebugManager.error(errCode, err.message);

				this.error(errCode, 'Unable to load viewer');

			}, this));
		},

		loadSandboxedViewer: function(){

			console.warn('Loader => Using sandboxed viewer');

			var $iframe = $('<iframe>');

			// Generate sandboxed source URL
			var url = URL( Calameo.defaults.base.url );

			var params = [];

			_.each( LoaderConfigSrvc.get(), function(value, key){
				// Remove sandboxed parameter
				if ( key == 'sandboxed' ) return;
				if ( value === LoaderConfigSrvc.defaults[key] ) return;
				params.push( [key, encodeURIComponent(value)] );
			});

			_.each( BookConfigSrvc.get(), function(value, key){
				// Remove sandboxed parameter
				params.push( [key, encodeURIComponent(value)] );
			});

			url.query(params);

			$iframe
				.attr({
					src				: url.toString(),
					width			: '100%',
					height			: '100%',
					frameborder		: '0',
					scrolling		: 'no',
					allowfullscreen	: true,
					allow           : 'autoplay'
				})
				.css({
					position		: 'fixed',
					border 			: 'none',
					width 			: '100%',
					height 			: '100%',
					left			: 0,
					top 			: 0,
					right			: 0,
					bottom 			: 0
				});

			$('body').append( $iframe );

			console.log('Loader => Sandboxed viewer added');
		},

		isForced: function () {
			return _.indexOf( ['mobile', 'desktop', 'fallback', 'twittercard'], LoaderConfigSrvc.get('forceViewer') ) >= 0;
		},

		isMobile: function(){
			return Util.isMobile();
		},

		isDesktopHtml5: function () {

			// console.log('Loader => Checking feature [csstransforms3d]:',	Modernizr.csstransforms3d);
			console.log('Loader => Checking feature [cors]:',			 	Modernizr.cors);
			// console.log('Loader => Checking feature [csstransforms3d]:',	Modernizr.csstransforms3d);
			console.log('Loader => Checking feature [svg]:', 				Modernizr.svg);
			// console.log('Loader => Checking feature [svgclippaths]:',		Modernizr.svgclippaths);
			console.log('Loader => Checking feature [inlinesvg]:',			Modernizr.inlinesvg);
			// console.log('Loader => Checking feature [fullscreen]:',			Modernizr.fullscreen);
			console.log('Loader => Checking feature [html5]:',				LoaderConfigSrvc.get('html5') == true);

			return Modernizr.cors
					&& Bowser.check({msie: '10'})
					// && Modernizr.csstransforms3d // Sometime returns false negatives replaced it with a version detection
					&& Modernizr.svg
					// && Modernizr.svgclippaths
					&& Modernizr.inlinesvg
					// && Modernizr.fullscreen // Disabled because IE10 does not have fullscreen capabilities
					&& LoaderConfigSrvc.get('html5') == true;
		},

		isDesktopFlash: function () {
			return Util.flash.hasFlash() && ( parseFloat(Util.flash.getFlashVersion().replace(/,/g, '.')) >= 9 );
		},

		isDesktopFallback: function () {
			return true;
		}
	}))();

});

