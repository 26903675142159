define('manager-debug',[
	'underscore',
	'status',
	'util',
	'manager',
	'manager-event',
	'manager-viewport',
	'manager-notification',
	'service-config-loader',
	'service-config-book',
	'service-data-book',
	'service-data-locales',
	'service-data-debug',
	'modernizr',
	'ravenjs'
], function (_, $S, Util, Manager, EventManager, ViewportManager, NotificationManager, LoaderConfigSrvc, BookConfigSrvc, BookSrvc, LocalesSrvc, DebugSrvc, Modernizr, Raven) {

	return Manager.extend({}, {

		_logs: [],
		_times: {},

		_avgTimes: {},
		_tempAvgTimes: {},

		_checked: false,

		debuggerView: null,

		enable: function()
		{
			console.log('DebugManager => Enabled');

			EventManager.on('keypress', this.onKeyPressHandler, this);
			EventManager.on('debug', this.onDebugHandler, this);

			// $(window).on('error', this.onErrorHandler.bind(this));
			// window.onerror = $.proxy(this.onErrorHandler, this); // Using onerror instead of JQuery to get the stacktrace if available

			this._throttledSendDebugData = _.debounce( $.proxy(this.sendDebugData, this), 5000, true);

			Manager.enable.apply(this, arguments);
		},
		disable: function()
		{
			console.log('DebugManager => Disabled');

			EventManager.off('keypress', this.onKeyPressHandler, this);
			EventManager.off('debug', this.onDebugHandler, this);

			// $(window).off('error', this.onErrorHandler.bind(this));
			// window.onerror = null

			Manager.disable.apply(this, arguments);
		},

		check: function(){

			if ( this._checked ) return;

			this._checked = true;

			// Track incorrect window.location.href to detect cheaters
			var pattern = new RegExp(window.atob('Xmh0dHBzPzpcL1wvKD86W14uXStcLik/Y2FsYW1lbyg/OmFzc2V0cyk/XC4oPzpjb218dGVzdClcLw=='), 'i');
			if ( !pattern.test(window.location.href) )
			{
				this._throttledSendDebugData('domain', false);
			}
		},

		info: function(msg, metas){
			this._logs.push({
				type: 'log',
				msg: msg,
				metas: metas || {}
			});
			console.info(msg, metas);
		},
		warn: function(msg, metas){
			this._logs.push({
				type: 'warning',
				msg: msg,
				metas: metas || {}
			});
			console.warn(msg, metas);
		},
		error: function(msg, metas){
			this._logs.push({
				type: 'error',
				msg: msg,
				metas: metas || {}
			});
			console.error(msg, metas);
			Raven.captureException(new Error(msg), metas);
		},

		onDebugHandler: function(e, data){
			var type = data.type.toLowerCase();
			if ( ['info', 'warn', 'error'].indexOf(type) >= 0 ) this[type](data.msg || '', data.metas || {});
		},

		// onErrorHandler: function(e){
		// 	var msg = e.originalEvent.message;
		// 	var err = {};
		// 	this.error(msg, err);
		// },
		onErrorHandler: function(message, filename, lineno, colno, error)
		{
			var msg = message + ' (' + ( filename || '?' ) + '@' + lineno + ':' + colno + ')';
			var metas = {
				stack: error != null ? error.stack : ''
			};
			this.error(msg, metas);

			/*
			// Notify the error to the platform
			DebugSrvc
				.load({
					data: {
						type: 'error',
						error: 915,
						payload: JSON.stringify( this.getDebugData() )
					}
				});
			*/
		},

		startTime: function(key){
			if ( this._times[key] ) return;
			this._times[key] = {
				startTime: Date.now()
			};
		},
		endTime: function(key){
			if ( !this._times[key] ) return;
			var t = this._times[key];
			t.endTime = Date.now();
			t.totalTime = t.endTime - t.startTime;
			// console.debug('DebugManager =>', key, ' -> ', t.totalTime + 'ms');
		},
		startAvgTimeCounter: function(key){
			var now = Date.now();
			var id = key + '_' + now;
			if ( this._tempAvgTimes[id] ) return;
			// console.debug('DebugManager => Starting average time:', key, id);
			this._tempAvgTimes[id] = {
				key: key,
				startTime: now
			};
			return id;
		},
		endAvgTimeCounter: function(id){
			var temp = this._tempAvgTimes[id];
			if ( !temp ) return;
			var key = temp.key;
			var now = Date.now();
			var duration = now - temp.startTime;
			var avgTime = this._avgTimes[key] || {count: 0, totalTime: 0, avgTime: 0};
			avgTime.count++;
			avgTime.totalTime += duration;
			avgTime.avgTime = Math.round( avgTime.totalTime / avgTime.count );
			this._avgTimes[key] = avgTime;
			// console.debug('DebugManager => Ending average time:', key, avgTime);
			delete this._tempAvgTimes[id];
		},
		onKeyPressHandler: function(e)
		{
			if ( ['input','select','textarea'].indexOf(e.target.nodeName.toLowerCase()) >= 0 ) return;

			if ( e.which == 68 && e.shiftKey == true )
			{
				if ( confirm( LocalesSrvc.t('msg.confirmDebugSend') ) ) this._throttledSendDebugData('user', true);
			}

			if ( e.which == 77 && e.shiftKey == true )
			{
				this.toggleDebugger();
			}
		},
		getDebugData: function(){
			// Build the debug
			var data = {
				config: {
					loader: LoaderConfigSrvc.get(),
					book: BookConfigSrvc.get()
				},
				referrer: Util.url.parent().toString(),
				navigator: _.pick(_.extend({}, navigator), 'userAgent', 'language', 'languages', 'onLine', 'vendor'),
				screen: _.extend({}, screen),
				viewport: ViewportManager.getRect().get(),
				features: {
					cors: 			Modernizr.cors,
					svg: 			Modernizr.svg,
					inlinesvg: 		Modernizr.inlinesvg,
					svgasimg: 		Modernizr.svgasimg,
					fullscreen: 	Modernizr.fullscreen,
					postmessage: 	Modernizr.postmessage
				},
				context: $('body').attr('class'),
				ad: {size: $S.adSize || null},
				url: (parent !== window) ? document.referrer : document.location.href,
				book: _.pick(BookSrvc.get(), 'features'),
				times: this._times,
				avgs: this._avgTimes,
				logs: this._logs
			};
			console.log('DebugManager => Data:', data);
			return data;
		},
		sendDebugData: function(type, notify){

			console.log('DebugManager => Sending debug data:', type);

			// notif can be empty if the notification manager is disabled
			var notif;

			if ( notify ) notif = NotificationManager.info( LocalesSrvc.t('Sending debug information. Please wait...') );

			DebugSrvc
				.load({
					data: {
						type: type,
						payload: JSON.stringify( this.getDebugData() )
					}
				})
				.done(function(){
					if ( notif ) notif.hide();
					if ( notify ) NotificationManager.success( LocalesSrvc.t('Debug information sent successfuly!'), 3000 );
				})
				.fail(function(){
					if ( notif ) notif.hide();
					if ( notify ) NotificationManager.error( LocalesSrvc.t('Unable to send debug information!'), 3000 );
				});
		},

		toggleDebugger: function(){
			console.log('DebugManager => Showing debugger');
			if ( this.debuggerView )
			{
				this.debuggerView.destroy();
				this.debuggerView = null;
				$S.debug(false);
			}
			else
			{
				var module = 'debugger';
				require([module], $.proxy(function(DebuggerView){
					this.debuggerView = new DebuggerView();
					$S.debug(true);
				}, this));
			}
		}
	});

});
